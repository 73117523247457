import React, { Fragment } from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero';

const Products = ({ data }) => (
  <Layout>
    <SEO />
    <Hero title="Title goes here" />

    <section>
      <div className="container">
        <div className="flex flex-wrap justify-center">
          <div className="w-full">
            <p>Products Page</p>

            {data.allPrismicProduct.edges.map(({ node }, id) => (
              <div key={node.id} className="w-full sm:w-1/2 lg:w-1/3 sm:px-4">
                <Fragment>
                  <h3>{node.data.size}</h3>
                  <p>{node.data.product_name.text}</p>
                  {node.data.product_info.map((item, id) => (
                    <div key={id}>
                      {item.item_id}
                      {item.item_download.url && <a href="{item.item_download.url}">download</a>}
                    </div>
                  ))}
                </Fragment>
              </div>
            ))}

            <Link to="/">
              <p>Go home</p>
            </Link>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default Products;

export const query = graphql`
  query productsList {
    allPrismicProduct {
      edges {
        node {
          id
          data {
            size
            product_name {
              html
              text
            }
            product_info {
              item_id
              item_download {
                link_type
                name
                kind
                url
                size
                target
              }
            }
          }
        }
      }
    }
  }
`;
